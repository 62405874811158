import React, { Suspense, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { lazyLoadImages } from '../../../Admin/Utils.js';
import ArrowD from '../../../../Assets/img/green-darrow.svg';
import { SearchVisibilityContext } from '../../Layouts/SearchVisibilityContext.js';
const Banner = React.lazy(() => import('./Banner.js'));
const DeveloperListing = React.lazy(() => import('../../Layouts/DeveloperListing.js'));
const Clients = React.lazy(() => import('./Clients.js'));
const Process = React.lazy(() => import('./Process.js'));
const Tools = React.lazy(() => import('./Tools.js'));
const TheEnd = React.lazy(() => import('./TheEnd.js'));
const CaseStudies = React.lazy(() => import('./CaseStudies.js'));
const Work = React.lazy(() => import('./Work.js'));

const Bwstudio = () => {
    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const location = useLocation();
    const { setisBwStudioPage } = useContext(SearchVisibilityContext)

    useEffect(() => {
        // Set the state to true when this component mounts (user is on the details page)
        setisBwStudioPage(true);

        // Clean up: Set the state to false when the component unmounts (user navigates away)
        return () => setisBwStudioPage(false);
    }, [location.pathname, setisBwStudioPage]);
    const scrollToSectionWithMargin = (ref, offset = 50) => {
        if (ref?.current) {
          const elementPosition = ref.current.offsetTop;
          const offsetPosition = elementPosition - offset;
    
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      };
    useEffect(() => {
        const timer = setTimeout(() => {
            lazyLoadImages();
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
  return (
    <>
        <div className='bwstudio'>
            <section id="banner">
                <Suspense fallback={<div>Loading...</div>}>
                    <Banner/>
                </Suspense>
                <button className='btnclick' onClick={() => scrollToSectionWithMargin(section2Ref)}><img src={ArrowD} loading='lazy' alt="hero-image" className="img-fluid d-block" /></button>
            </section>
            <section ref={section2Ref} id="devlisting" className="devlisting-studio pt-lg-5 mt-lg-5">
                <h2 class="studio-head text-center">Our Clients</h2>
                <Suspense fallback={<div>Loading...</div>}>
                    <DeveloperListing />
                </Suspense>
            </section>
            <section id="clients">
                <Suspense fallback={<div>Loading...</div>}>
                    <Clients id="clients"/>
                </Suspense>
            </section>
            <Suspense fallback={<div>Loading...</div>}>
                <Work id="work"/>
            </Suspense>
            <section id="process" className='pt-5'>
                <Suspense fallback={<div>Loading...</div>}>
                    <Process id="process"/>
                </Suspense>
            </section>

            <Suspense fallback={<div>Loading...</div>}>
                <CaseStudies id="casestudies"/>
            </Suspense>
            <section id="tools">
                <Suspense fallback={<div>Loading...</div>}>
                    <Tools id="tools"/>
                </Suspense>
            </section>
            <section id="theend" className='py-5'>
                <Suspense fallback={<div>Loading...</div>}>
                    <TheEnd id="theend"/>
                </Suspense>
            </section>
        </div>
    </>
  );
}

export default Bwstudio;